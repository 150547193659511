import React from 'react';
import { getThemeComponent } from '@/utils/themeImports';
import { AddPagesLib as _addPageLib } from '@dotpe/additional-pages';
import { specificThemeRender } from '@/utils/specificThemeRender';
import { useSSRSelector } from '@/redux/ssrStore';
import ListPresentation from 'src/components/DesignSystem/Widgets2.0/ListPresentation';
import { cloneDeep } from 'lodash';
import { ADDITIONAL_PAGE_TYPE, LAYOUT_TYPE } from './constant';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import {
  TESTIMONIAL_IMAGE_POSITION,
  TESTIMONIAL_LAYOUT_TYPES,
} from 'src/components/DesignSystem/Widgets2.0/ListPresentation/components/Testimonials/Constants';

const AdditionalPage = (props) => {
  const { storeInfo, catalogCategories, widgetData, widgetInfo } = useSSRSelector(
    (state) => ({
      storeInfo: state.storeReducer.store,
      catalogCategories: state.catalogReducer.categories,
      widgetData: state.additionalPagesReducer.additionalPageList,
      widgetInfo: state.jsonWidgetsReducer.widgets,
    })
  );
  const isMobile = isMobileDevice();

  const isHomePage = props.source === ADDITIONAL_PAGE_TYPE.HOME;
  const newBlogWidgetData = Object.values(widgetInfo)?.find(
    (data) => data?.name === ADDITIONAL_PAGE_TYPE.NEW_BLOG
  );
  const newTestimonialData = Object.values(widgetInfo)?.find(
    (data) => data?.name === ADDITIONAL_PAGE_TYPE.NEW_TESTIMONIALS
  );

  const isNewBlogWidget =
    newBlogWidgetData &&
    props._addPage?.structure_type !== 0 &&
    props._addPage?.page_type === ADDITIONAL_PAGE_TYPE.BLOG;

  const isTestimonialPage =
    props._addPage?.page_type === ADDITIONAL_PAGE_TYPE.TESTIMONIAL && newTestimonialData;
  const isBlogOrTestimonialPage = isNewBlogWidget || isTestimonialPage;
  // const [widgetData, setWidgetData] = useState({}); // if props.source is home then use widgetdata to render
  const _renderUnstructuredPageData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: props?._addPage?.html_data,
        }}
      />
    );
  };

  function getBlogProps(widgetData) {
    const updatedBlogData = cloneDeep(widgetData);
    const { listPresentation } = updatedBlogData;
    listPresentation.textAlign = 'center';
    listPresentation.numColumnsDesktop = 3;
    listPresentation.numColumnsMobile = 2;
    listPresentation.layoutType = LAYOUT_TYPE.GRID;
    return updatedBlogData;
  }

  function getTestimonialData(testimonialData) {
    const updatedTestimonialData = cloneDeep(testimonialData);
    const shouldUseL2 =
      updatedTestimonialData.listPresentation?.layoutName ===
      TESTIMONIAL_LAYOUT_TYPES.FOUR;

    return {
      ...updatedTestimonialData,
      listPresentation: {
        ...updatedTestimonialData.listPresentation,
        layoutType: LAYOUT_TYPE.GRID,
        ...(shouldUseL2 ? { layoutName: TESTIMONIAL_LAYOUT_TYPES.TWO } : {}),
      },
      cardFrame: {
        ...updatedTestimonialData.cardFrame,
        ...(shouldUseL2
          ? { authorImagePosition: TESTIMONIAL_IMAGE_POSITION.WITH_AUTHOR_INFO }
          : {}),
      },
    };
  }

  const renderNewBlogWidgetComp = () => {
    return (
      <ListPresentation
        widgetOptions={getBlogProps(newBlogWidgetData?.props)}
        blogData={props?._addPage?.json_data?.blog?.users_list?.list}
        isBlogListingPage
        overrides={{
          layout: {
            styles: {
              background: '',
            },
          },
        }}
      />
    );
  };

  function renderTestimonialPage() {
    return (
      <ListPresentation
        widgetOptions={getTestimonialData(newTestimonialData?.props)}
        isTestimonialListingPage
      />
    );
  }

  function renderBlogAndTestimonialPage() {
    if (isNewBlogWidget) {
      return renderNewBlogWidgetComp();
    }
    return renderTestimonialPage();
  }

  const _renderStructuredPageData = () => {
    let dataToRender = props?._addPage?.json_data || {};
    if (isHomePage) {
      // if the source is home then  use state because on home there can be multiple widgets
      dataToRender = widgetData?.json_data || {};
    }
    return dataToRender ? (
      !!Object.keys(dataToRender).length &&
        Object.keys(dataToRender).map((template) => {
          const CompJSX = _addPageLib(template);
          const templateData = dataToRender[template];
          if (props?.config?.max_items)
            templateData['max_items'] = props?.config?.max_items;
          if (props.slug) templateData['slug'] = props.slug;
          return (
            <CompJSX
              section={dataToRender[template]}
              templateId={template}
              source={'cfe'}
              onElementClick={() => {}}
              key={template}
            />
          );
        })
    ) : (
      <></>
    );
  };

  return (
    <div className="relative">
      {!isHomePage &&
        specificThemeRender(storeInfo)?.components?.Header?.map((layout) => {
          return getThemeComponent(layout.sub_type, layout.name, {
            storeData: storeInfo,
            categories: catalogCategories || [],
            ...layout,
          });
        })}
      <div
        className={`${isTestimonialPage ? `tw-flex tw-flex-col ${isMobile ? 'tw-mt-[20px]' : ''}` : 'additional--wrapper'} ${props?.config?.max_items ? 'home-page' : ''}`}
      >
        {!isBlogOrTestimonialPage ? (
          <section className="additional-main">
            {props._addPage?.structure_type === 0
              ? _renderUnstructuredPageData()
              : _renderStructuredPageData()}
          </section>
        ) : (
          renderBlogAndTestimonialPage()
        )}

        {!isHomePage &&
          specificThemeRender(storeInfo)?.components?.Footer?.map((layout) => {
            return getThemeComponent(layout.sub_type, layout.name, {
              storeData: storeInfo,
              categories: catalogCategories || [],
              ...layout,
            });
          })}
      </div>
    </div>
  );
};

export default AdditionalPage;
